import { Fragment } from 'react';
import Body from './Component/Body';

import { ThemeProvider } from '@mui/material';
import theme from './Component/UI/theme';


function App() {
  return (
    <>
    <ThemeProvider theme={theme}>
      <Body />
    </ThemeProvider>
    
    </>
  );
}

export default App;
