import { Grid, Box, Typography } from '@mui/material';
import { Fragment } from 'react';

import logo from "../images/cropped-whatsgood-logo.png"

const Body = () =>{
    return(
        <Fragment>
            <Box bgcolor="primary.main">
            <Grid container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center">
                        <img alt='Logo' src={logo} width="350vw"></img>
                    </Grid>
            </Box>
            <Box bgcolor="primary.main">
            <Grid container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
					style={{ minHeight: '90vh' }}>
                <Typography variant="h5" color="red">公眾投票已經結束</Typography>     
            </Grid>
        </Box>
        <Box bgcolor="primary.main" paddingTop="5em" paddingBottom="1em">
				<Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" marginBottom="2em">
					<Typography align="center" variant="p" color="red">
						<div>Whats Good Music Awards 8-2-2022</div>
						<div>Hong Kong Convention and Exhibition Centre</div>
                        <div>Hall 5BC</div>
					</Typography>
				</Grid>
			</Box>
        </Fragment>
        
    );
}

export default Body;