import { createTheme } from "@mui/material";

const dark = '#000000';
const light = '#fffff';

const theme = createTheme({
	palette: {
		primary: {
			main: `${dark}`,
			contrastText: `${light}`
		},
		background: {
			default: `${dark}`
		},
		text: {
			primary: `${light}`
		}
	},
	breakpoints: {
		values: {
			mobile: 0,
			tablet: 640,
			laptop: 1024,
			desktop: 1200
		}
	}
});

export default theme;